/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import Pill from "../components/Pill";
import Grid from "../components/Grid";
import Separator from "../components/Separator";
import QuickActivityFinder from "../components/QuickActivityFinder";

import { WelcomeToOurWebsite } from "../assets/images/brailleheaders";

const Teaser = ({
  title,
  body,
  image,
  buttonLabel,
  buttonAriaLabel,
  linkTo,
  style = {},
}) => (
  <div
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      ...style,
    }}
  >
    <img
      src={image.url}
      alt=""
      sx={{
        width: "100%",
        height: ["calc(100vw - 5rem)", "calc(50vw - 3rem)", "20rem"],
        objectFit: "cover",
      }}
    />
    <Themed.h3 sx={{ variant: "text.h4", marginTop: "30px" }}>
      {title}
    </Themed.h3>
    <Themed.p sx={{ flex: 1, marginTop: "20px", marginBottom: "30px" }}>
      {body}
    </Themed.p>
    <Pill
      sx={{ marginTop: "auto" }}
      label={buttonLabel}
      linkTo={linkTo}
      ariaLabel={buttonAriaLabel}
    />
  </div>
);

const Index = ({
  pageContext: {
    homePage: {
      title,
      subtitle,
      aboutTitle,
      aboutBody,
      aboutReference,
      aboutImage,
      aboutButtonLabel,
      aboutButtonAriaLabel,
      learningThroughPlayTitle,
      learningThroughPlayBody,
      learningThroughPlayImage,
      learningThroughPlayButtonLabel,
      learningThroughPlayButtonAriaLabel,
      learningThroughPlayLinkTo,
      seo,
    },
    activities,
    activityGroups,
  },
}) => {
  return (
    <Layout
      title={seo.title || "Home"}
      description={seo.description}
      showBreadcrumbs={false}
      brailleTextImage={WelcomeToOurWebsite}
    >
      <Themed.h1 sx={{ marginBottom: "20px" }}>{title}</Themed.h1>
      <Themed.h2 sx={{ variant: "text.h4" }}>{subtitle}</Themed.h2>
      <Grid
        columns={[1, 2]}
        gap={4}
        sx={{
          marginTop: "5rem",
        }}
      >
        <Teaser
          title={aboutTitle || aboutReference.title}
          body={aboutBody || aboutReference.summary}
          image={aboutImage || aboutReference.cardImage}
          buttonLabel={aboutButtonLabel}
          buttonAriaLabel={aboutButtonAriaLabel}
          linkTo={`/facilitation/${aboutReference.uid}`}
        />
        <Teaser
          title={learningThroughPlayTitle}
          body={learningThroughPlayBody}
          image={learningThroughPlayImage}
          buttonLabel={learningThroughPlayButtonLabel}
          buttonAriaLabel={learningThroughPlayButtonAriaLabel}
          linkTo={learningThroughPlayLinkTo}
        />
      </Grid>
      <Separator />
      <QuickActivityFinder
        activities={activities}
        activityGroups={activityGroups}
      />
    </Layout>
  );
};

export default Index;
