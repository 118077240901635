/** @jsx jsx */
import { jsx } from "theme-ui";

const Separator = ({ style = {} }) => (
  <div
    sx={{
      width: "100%",
      height: "2px",
      bg: "separator",
      margin: "60px 0",
      ...style,
    }}
  />
);

export default Separator;
