/** @jsx jsx */
import React from "react";
import { jsx, Themed } from "theme-ui";
import Pill from "components/Pill";

export default function FilterSection({
  level,
  filters,
  header,
  onClickFilter,
  filterState,
  style,
}) {
  return (
    <div sx={{ width: ["100%", "initial"], ...style }}>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: [0, 0, "2rem"],
          marginBottom: "20px",
        }}
      >
        <Themed.p sx={{ margin: 0 }}>{header}</Themed.p>
      </div>
      <ul
        sx={{
          listStyleType: "none",
          display: "flex",
          flexDirection: "row",
          flexWrap: ["wrap", "nowrap"],
          justifyContent: ["flex-start", "space-between"],
          borderLeftWidth: [0, null, "2px"],
          borderLeftStyle: "solid",
          borderLeftColor: "separator",
          padding: [0, 0, "0 2rem"],
        }}
      >
        {filters.map(({ name, uid }, index) => (
          <li key={uid}>
            <Pill
              label={name}
              ariaLabel={level ? `${level} ${name}` : name}
              onClick={() => onClickFilter(uid)}
              active={filterState[uid]}
              style={{
                marginRight: index < filters.length - 1 ? "1rem" : 0,
                marginBottom: ["1rem", 0],
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
